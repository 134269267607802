
export interface ProgressiveRequestAttributes {
	url: string;
	method?: string;
	data?: Record<string, unknown>;
	receiving(data: Record<string, unknown>): void;
	received(): void;
}

const separator = '#&!&#';
export function ProgressiveRequest(props: ProgressiveRequestAttributes) {
	
	console.log('ProgressiveRequest');
	console.log(JSON.stringify(props.data));

	fetch(props.url, {
		method: props.method?.toUpperCase() || "GET",
		headers: {
			//"Content-type": "text/event-stream",
			"Content-type": "text/html"
		},
		body: JSON.stringify(props.data)
	})
	.then((response) => {

		console.log(response.status);
		//console.log(window.location.pathname);
		if(response.status == 401){
			let pathname = null;
			if(window.location.pathname != '/'){
				pathname = window.location.pathname;
			}
			window.location.assign(`/${pathname?'?goto='+pathname:''}`);
		}

		if(!response.body){
			return null;
		}

		const reader = response.body.getReader();

		function readAgain(){

			reader.read()
			.then(({ done, value }) => {
				// If there is no more data to read
				if (done) {
					console.log("done", done);
					props.received();
					return;
				}else{
					const text = new TextDecoder("utf-8").decode(value);
					console.log(text);
					const texts = text.split(separator);
					texts.pop();
					for(let item of texts){
						props.receiving(JSON.parse(item));
					}
					readAgain();
				}
			});

		}

		readAgain();

	});
	
	/*
	let last_index = 0;
	const xhr = new XMLHttpRequest();
	xhr.open(props.method?.toUpperCase() || "GET", props.url, true);
	xhr.timeout = 100000;
	xhr.setRequestHeader("Content-type", "text/event-stream");
	//xhr.setRequestHeader("Content-type", "text/html");
	xhr.onprogress = function (e){
		const curr_index = xhr.responseText.length;
		if (last_index == curr_index) return; 
		let s = xhr.responseText.substring(last_index, curr_index);
		last_index = curr_index;
		props.receiving(JSON.parse(s));
	};
	xhr.onreadystatechange = function(event) {  
        if (xhr.readyState == 4){  
            props.received();
        }  
    };
	xhr.send(JSON.stringify(props.data));
	*/
	return true;
}
